<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-2 align-items-stretch g-4 py-5">
        <div class="col">
          <div class="card card-cover h-100 overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-1 text-blue">
              <h4 class="text-blue fw-bolder">Time</h4>
            </div>
            <div class="d-flex flex-column p-4 pb-3 text-blue text-shadow-1">
              <div class="d-flex justify-content-between align-items-center">
                <h6 class="card-subtitle mb-2 text-blue">Total worked today</h6>
                <h2 class="card-title">{{Total.today}}</h2>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <h6 class="card-subtitle mb-2 text-blue">To prev day</h6>
                <h2 class="card-title">{{Total.prevDay}}</h2>
              </div>
            </div>
          </div>
        </div>
        <!-- 3er card -->
        <div class="col">
          <div class="card card-cover h-100 text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div class="d-flex flex-column mb-3  text-blue">
                <h4 class="text-blue fw-bolder">Activity Settings</h4>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                <h3 class="card-subtitle mb-2 text-blue">
                  <i class="fas fa-chart-line margin-r" aria-hidden="true"></i>
                </h3>
              </div>
              <div class="d-flex justify-content-between align-items-center mt-1">
                <Multiselect v-model="timezoneSelect.value" v-bind="timezoneSelect" @select="SelectedTimezone"></Multiselect>
              </div>
              <div class="d-flex justify-content-between align-items-center mt-1">
                <Multiselect v-model="MultiSelectProject.value" v-bind="MultiSelectProject" @select="SelectedProject" ref="multiselect" class="size-multiselect"></Multiselect>
              </div>
              <div class="d-flex justify-content-between align-items-center mt-1">
                <v-date-picker v-model="datecalendar" :model-config="modelConfig" @dayclick="onDayClick">
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="form-control"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </v-date-picker>
              </div>
              <div class="d-flex justify-content-between align-items-center mt-3">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" @change="SelectCheck()">
                  <label class="form-check-label" for="flexSwitchCheckDefault">Remove blocks from timeless hours</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" id="custom-cards">
      <h2 class="pb-0  text-dark-blue">Screenshots</h2>
      <div v-for="(myobj, mykey, myindex) in ListMin" :key="myindex">
        <!-- Divider -->
        <div class="divider">
          <span></span>
          <span> {{myobj.hour}}:00:00 / Total time worked: {{Math.floor(myobj.min/60)}} minute(s) and {{Math.round(((myobj.min/60)-Math.floor(myobj.min/60))*60)}} second(s) </span>
          <span></span>
        </div>
        <!-- end divder -->
        <div class="row row-cols-1 row-cols-lg-6 align-items-stretch g-4 py-5">
          <div :v-if="Verify(ListBlocks.status)" v-for="(obj, key, _index) in  ListBlocks2.list[mykey]" :key="_index" class="col h-100">
            <div v-if="obj.time_init != ''">
              <div align="center">
                <p>{{ $filters.shortTime(obj.init_hour) }} - {{ $filters.shortTime(obj.finaly_hour) }}</p>
              </div>
              <img class="img-fluid" loading="lazy" :src="obj.screenshot" style="width:100%;">
              <div class="card card-cover h-100 overflow-hidden text-white bg-white rounded-5 shadow-lg">
                <div class="d-flex flex-column  p-2 pb-3 text-blue text-shadow-1">
                  <p class="mb-0 text-blue text-center">Project</p>
                  <p class="mt-0 mb-2 text-blue text-center">To do</p>
                  <p class="mt-0 mb-2 text-blue text-center"> {{obj.avg}} of {{obj.min}} minutes </p>
                  <div class="progress mb-1">
                    <div class="progress-bar" role="progressbar" :style="{ width: obj.avg }" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="'Overall: ' + obj.avg"> {{obj.avg}}</div>
                  </div>
                  <div class="d-flex justify-content-around">
                    <button type="button" class="btn text-blue p-0" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Screen" @click="showMultiple(obj)">
                      <i class="fa fa-eye"></i>
                    </button>
                    <button type="button" class="btn text-blue p-0" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete screenshot" @click="deleteBlock(obj)">
                      <i class="fa fa-trash"></i>
                    </button>
                    <!--<a href="#" @click="showMultiple(obj)" class="text-blue" data-bs-toggle="tooltip" data-bs-placement="bottom" title="View Screen">
                      <i class="fa fa-eye"></i>
                    </a>
                    <a href="#" class="text-blue" @click="deleteBlock(obj)"  data-bs-toggle="tooltip" data-bs-placement="bottom" title="Delete screenshot">
                      <i class="fa fa-trash"></i>
                    </a>-->
                  </div>
                </div>
              </div>
            </div>
            <!--NO ACTIVITY CARD-->
            <div v-if="obj.time_init == ''" class="col h-100">
              <div align="center">
                <p>{{ $filters.shortTime(obj.init_hour) }} - {{ $filters.shortTime(obj.finaly_hour) }}</p>
              </div>
              <div class="card card-cover h-100 overflow-hidden text-white bg-white rounded-5 shadow-lg">
                <div class="d-flex flex-column  p-2 pb-3 text-blue text-shadow-1">
                  <div align="center">
                    <i class="far fa-eye-slash"></i>
                    <h6>No Activity</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Confirm Deletion </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            {{modalMsg}}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button id="deleteConfirmButton" style="display: none;" type="button" class="btn btn-primary" @click="ConfirmDeletion" data-bs-dismiss="modal">Confirm</button>
          </div>
        </div>
      </div>
    </div>
    <!-- End modal -->
  </main>
  <!-- lightbox -->
  <div>
    <!-- all props & events -->
    <vue-easy-lightbox scrollDisabled escDisabled moveDisabled :visible="visible" :imgs="imgs" :index="index" @hide="handleHide"></vue-easy-lightbox>
  </div>
  <!-- ./lightbox -->
</template>

<script>
  import Api from '@/services/Request';
  // If VueApp is already registered with VueEasyLightbox, there is no need to register it here.
  import VueEasyLightbox from 'vue-easy-lightbox'
  import moment from 'moment-timezone'
  import Multiselect from '@vueform/multiselect'
  import $ from 'jquery';

  export default 
  {
    name: 'ContentScreenshot',
    components: 
    {
      VueEasyLightbox,
      Multiselect,
    },
    data: () => (
    {
      timezoneSelect: 
      {
        value: [moment.tz.guess(true)],
        closeOnSelect: true,
        options: [],
        searchable: true,
        hideSelected: true,
        showOptions: true
      },
      MultiSelectProject: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        hideSelected: true,
        showOptions: true
      },
      GetBlockPost: 
      {
        date: "",
        timezone: moment.tz.guess(true),
        id_project: null
      },
      GetBlockPostNoAll: 
      {
        date: "",
        timezone: moment.tz.guess(true),
        id_project: null,
        show_blocks: '1'
      },
      ToDeleteBlock: 
      {
        date: "",
        block: null,
        timezone: moment.tz.guess(true),
        id_project: ""
      },
      ToDeleteBlockValidated: 
      {
        date: "",
        block: null,
        timezone: moment.tz.guess(true),
        validate: true,
        id_project: ""
      },
      ListBlocks: 
      {
        status: "",
        list: {}
      },
      ListBlocks2: 
      {
        list: {}
      },
      newTime: 0,
      Total: 
      {
        status: "",
        today: "",
        prevDay: ""
      },
      CurrentDate: 
      {
        date: ""
      },
      ListMin: [],
      ListImgs: [],
      MyFlag: '',
      MyCounter: 0,
      imgs: '', // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
      myidproject: null,
      datecalendar: new Date(),
      modelConfig: 
      {
        type: 'string',
        mask: 'YYYY-MM-DD', 
      },
      modalMsg: '',
      flagNoAll: 0
    }),
    methods: 
    {
      ActualDate()
      {
        let date = "";
        let year = new Date().getFullYear();
        let month = new Date().getMonth() + 1;
        let  month2 =  month>=10?month:'0'+ month;
        let day = new Date().getDate();
        date = year+"-"+month2+"-"+day;
        return date;
      },
      ValidateFields() 
      {
        if( this.MultiSelectProject.value == "" || this.MultiSelectProject.value == null || this.timezoneSelect.value == null || this.timezoneSelect.value == "" || this.datecalendar == "")
        {
          return true
        }
      },
      onDayClick(day) 
      {
        this.GetBlockPost.date = this.datecalendar;
        this.GetBlockPostNoAll.date = this.datecalendar;
        this.ToDeleteBlock.date = this.datecalendar;
        this.ToDeleteBlockValidated.date = this.datecalendar;

        if(this.ValidateFields() != true)
        {
          this.GetBlocks();
          this.GetTotalDay();
        }
        else 
        {
          this.ListMin = [];
          this.Total.today = "00:00:00";
          this.Total.prevDay = "00:00:00";
          this.Total.avg = "0%";
          this.Total.avgPrev = "0%";
          this.Total.avgOrg = "0%";
        }
      },
      SelectedTimezone(option) 
      {
        this.GetBlockPost.timezone = option;
        this.GetBlockPostNoAll.timezone = option;
        this.ToDeleteBlock.timezone = option;
        this.ToDeleteBlockValidated.timezone = option;
      },
      deleteBlock(obj) 
      {
        this.ToDeleteBlock.block = obj.id;
        this.ToDeleteBlockValidated.block = obj.id;

        Api.post("/activity/delete", this.ToDeleteBlock).then((result) => 
        {
          //console.log(result.data.msg);
          this.modalMsg = result.data.msg;
          if(result.data.status == 'true')
          {
            $("#deleteConfirmButton").css({"display":"inline"});
          }
          else
          {
            $("#deleteConfirmButton").css({"display":"none"});
          }

          //$('#exampleModal').modal('show');
          /*var answer = window.confirm(this.modalMsg);

          if (answer) 
          {
            this.ConfirmDeletion();
          }*/
          var myModal = new bootstrap.Modal(document.getElementById('exampleModal'))
          myModal.show()
        });
      },
      ConfirmDeletion() 
      {
        //console.log("Confirmed");
        Api.post("/activity/delete", this.ToDeleteBlockValidated).then((result) => 
        {
          if(this.ValidateFields() != true)
          {
            this.GetBlocks();
            this.GetTotalDay();
          }
          else 
          {
            this.ListMin = [];
            this.Total.today = "00:00:00";
            this.Total.prevDay = "00:00:00";
            this.Total.avg = "0%";
            this.Total.avgPrev = "0%";
            this.Total.avgOrg = "0%";
          }
        });
      },
      SelectedProject(option) 
      {
        this.myidproject = option;
        
        //Test Blocks
        this.GetBlockPost.id_project = this.myidproject;
        this.GetBlockPostNoAll.id_project = this.myidproject;
        this.ToDeleteBlock.id_project = this.myidproject;
        this.ToDeleteBlockValidated.id_project = this.myidproject;

        if(this.ValidateFields() != true)
        {
          this.GetBlocks();
          this.GetTotalDay();
        }
        else 
        {
          this.ListMin = [];
          this.Total.today = "00:00:00";
          this.Total.prevDay = "00:00:00";
          this.Total.avg = "0%";
          this.Total.avgPrev = "0%";
          this.Total.avgOrg = "0%";
        }
      },
      showMultiple(obj) 
      {
        this.imgs = [];
        this.ListImgs = [];
        let t = 0;
        
        for (const [key, value] of Object.entries(this.ListBlocks.list)) 
        {
          if (value.time_init != '') 
          {
            this.ListImgs[Number(value.id)] = t;
            this.imgs.push(
            {
              title: value.init_hour + ' - ' + value.finaly_hour + ' | ' + value.avg + ' of ' + value.min + ' minute(s)',
              src: value.screenshot
            });
            t++;
          }
        }

        this.index = this.ListImgs[Number(obj.id)];
        this.show()
      },
      show() 
      {
        this.visible = true
      },
      handleHide() 
      {
        this.visible = false
      },
      Verify(status) 
      {
        if (status != 'false') 
        {
          return true;
        } 
        else 
        {
          return false;
        }
      },
      GetTimeZone()
      {
        var moment = require('moment-timezone');
        let myzone = [];
        moment.tz.names().forEach(function(timezone) 
        {
          myzone.push(
          {
            value: timezone,
            label: timezone + ': ' + moment.tz(timezone).format('Z')
          });
          //sql2 += "array(\'zone_name\'=>\'"+timezone+"\',\'zone_utc\'=>\'"+moment.tz(timezone).format('Z')+"\'),";
        });
        this.timezoneSelect.options = myzone;
      },
      //./verify hours
      GetBlocks() 
      {
        this.GetTotalDay()
        let urlBlock;
        if(this.flagNoAll == 0)
        {
          urlBlock = this.GetBlockPost;
        }
        else
        {
          urlBlock = this.GetBlockPostNoAll;
        }
        
        this.ListMin = [];
        Api.post("/activity/list2", urlBlock).then((result) => 
        {
          this.ListBlocks.status = result.data.status

          if (this.ListBlocks.status == "true") 
          {
            this.ListBlocks.list = this.orderListBlocks(result.data.blocks);//result.data.blocks;
            this.ListBlocks.status = result.data.status;
            //Getting blocks
            let counter = 0;
            let hourTemp = "";
            let sumMin = 0;

            for (const [key, value] of Object.entries(this.ListBlocks.list)) 
            {
              if (counter == 0) 
              {
                this.ListMin.push(
                {
                  hour: value.init_hour.substr(0, 2),
                  min: value.seconds==undefined?0:value.seconds
                });

                hourTemp = value.init_hour.substr(0, 2);
                sumMin = sumMin + Number(value.seconds==undefined?0:value.seconds);
              } 
              else 
              {
                if (hourTemp != value.init_hour.substr(0, 2)) 
                {
                  this.ListMin[this.ListMin.length - 1].min = sumMin;
                  sumMin = 0;
                  sumMin = sumMin + Number(value.seconds==undefined?0:value.seconds);
                  
                  this.ListMin.push(
                  {
                    hour: value.init_hour.substr(0, 2),
                    min: value.seconds==undefined?0:value.seconds
                  });
                  hourTemp = value.init_hour.substr(0, 2);
                } 
                else 
                {
                  sumMin = sumMin + Number(value.seconds==undefined?0:value.seconds);
                  this.ListMin[this.ListMin.length - 1].min = sumMin;
                }
              }
              counter++;
            }

            for (const [key, value] of Object.entries(this.ListMin)) 
            {
              let min = 0;
              let values = this.ListBlocks.list.filter(function(e) 
              {
                return e.init_hour.substr(0, 2) == value.hour;
              });
              this.ListBlocks2.list[key] = values;
            }
            //./Getting blocks
          }
        }) //
      },
      GetTotalDay() 
      {
        if(Array.isArray(this.timezoneSelect.value) == true)
        {
          let cleanTimezone = this.timezoneSelect.value;
          var iterator = cleanTimezone.values();
          this.GetBlockPost.timezone = iterator.next().value
        }
        else
        {
          this.GetBlockPost.timezone = this.timezoneSelect.value;
        }
        
        Api.post("/activity/today", this.GetBlockPost).then((result) => 
        {
          this.Total.status = result.data.status
          
          if (this.Total.status == "true") 
          {
            this.Total.today = result.data.total;
            this.Total.prevDay = result.data.totalPrev;
          }
        })
      },
      GetCurrentDate() 
      {
        //var currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '-');
        var currentDateWithFormat = this.ActualDate();
        this.GetBlockPost.date = currentDateWithFormat
        this.GetBlockPostNoAll.date = currentDateWithFormat
        this.ToDeleteBlock.date = currentDateWithFormat
        this.ToDeleteBlockValidated.date = currentDateWithFormat
      },
      orderListBlocks(list) 
      {
        return list.sort((a, b) => a.time_init - b.time_init)
      },
      GetMembersProject() 
      {
        /****Memer vs Project****/
        Api.get('/projects-members/projects').then((result) => 
        {
          if (result.data.status == "true") 
          {
            if (result.data['member_projects'] != "") 
            {
              var membersprojects = []
              membersprojects = result.data['member_projects']
              let myprojects = [];

              myprojects.push(
              {
                value: '0',
                label: 'Show All'
              });
              
              for (var i = 0; i < membersprojects.length; i++) 
              {
                myprojects.push(
                {
                  value: membersprojects[i].id_project,
                  label: membersprojects[i].name
                });
              }
              
              this.MultiSelectProject.options = myprojects;
              this.$refs.multiselect.select(this.MultiSelectProject.options[0])
            }
          }
        });
        /************************/
      },
      SelectCheck()
      {
        var checkbox = document.getElementById('flexSwitchCheckDefault');
        if(checkbox.checked)
        {
          this.flagNoAll = 1;
          if(this.ValidateFields() != true)
          {
            this.GetBlocks();
            this.GetTotalDay();
          }
          else 
          {
            this.ListMin = [];
            this.Total.today = "00:00:00";
            this.Total.prevDay = "00:00:00";
            this.Total.avg = "0%";
            this.Total.avgPrev = "0%";
            this.Total.avgOrg = "0%";
          }
        }
        else
        {
          this.flagNoAll = 0;
          if(this.ValidateFields() != true)
          {
            this.GetBlocks();
            this.GetTotalDay();
          }
          else 
          {
            this.ListMin = [];
            this.Total.today = "00:00:00";
            this.Total.prevDay = "00:00:00";
            this.Total.avg = "0%";
            this.Total.avgPrev = "0%";
            this.Total.avgOrg = "0%";
          }
        }
      }
    },
    mounted() 
    {
      this.GetTimeZone()
      this.GetCurrentDate()
      //this.GetBlocks()
      this.GetTotalDay()
      this.GetMembersProject()
    }
  }
</script>

<style scoped>
  .divider {
    display: table;
    font-size: 14px;
    text-align: center;
    width: 75%;
    margin: 40px auto;
  }

  .divider span {
    display: table-cell;
    position: relative;
  }

  .divider span:first-child,
  .divider span:last-child {
    width: 50%;
    top: 13px;
    -moz-background-size: 100% 2px;
    background-size: 100% 2px;
    background-position: 0 0, 0 100%;
    background-repeat: no-repeat;
  }

  .divider span:first-child {
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(transparent), to(#bebebe));
    background-image: -webkit-linear-gradient(180deg, transparent, #bebebe);
    background-image: -moz-linear-gradient(180deg, transparent, #bebebe);
    background-image: -o-linear-gradient(180deg, transparent, #bebebe);
    background-image: linear-gradient(90deg, transparent, #bebebe);
  }

  .divider span:nth-child(2) {
    color: #e56b0a;
    padding: 0px 5px;
    width: auto;
    white-space: nowrap;
  }

  .divider span:last-child {
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#bebebe), to(transparent));
    background-image: -webkit-linear-gradient(180deg, #bebebe, transparent);
    background-image: -moz-linear-gradient(180deg, #bebebe, transparent);
    background-image: -o-linear-gradient(180deg, #bebebe, transparent);
    background-image: linear-gradient(90deg, #bebebe, transparent);
  }
</style>
